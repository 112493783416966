import * as React from 'react';

function Calendar(props) {
  return (
    <div className={'content-fundapap__app calendar__container'}>
      <div className={'calendar__table-next-meets'}>
      <h2 className={'calendar__title'}>Siguientes Reuniones:</h2>
        {props.nexts.map((e, index) =>
            <ul key={index} className="MuiList-root MuiList-padding css-1mcnwpj-MuiList-root">
              <li className="MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-alignItemsFlexStart css-hhvo2v-MuiListItem-root">
                <div className="MuiListItemText-root css-tlelie-MuiListItemText-root">
                  <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-10hburv-MuiTypography-root">
                  {e.title}
                  </span>
                </div>
              </li>
            </ul>
        )}
      </div>
    </div>
)};

export default Calendar;