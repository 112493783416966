import React, {useEffect, useState} from 'react';
import { useUser } from '../../hooks/use-user';
import { meetingHistory, listPayments } from "../../services";
import DataTable from "../../components/dataTable";
import {Button, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function UserPage() {
  const { user, accessToken, setAccessToken } = useUser();
  const [apiData, setApiData] = useState();
  const [apiData2, setApiData2] = useState();


  useEffect(() => {
    meetingHistory(accessToken,
        (data) => setApiData(data),
        (error) => setAccessToken(null)

        //() => true // Comentar arriba y descomentar aca para ver loading
    );

    listPayments(accessToken,
        (data) => setApiData2(data),
        (error) => setAccessToken(null)
    );
  }, []);



  const TableMeets = () => {
    let table1;
    let table2;

    const navigate = useNavigate();

    let headerst1 = [{
      id: 'title',
      title: 'Reuniones en las que haz participado'
    }];
    if (apiData && apiData.meetings) {

      table1 = <DataTable columns={headerst1} data={apiData.meetings} />;
    } else {
      table1 = <DataTable columns={headerst1} data={[]} />;
    }

    let headerst2 = [{
      id: 'fecha',
      title: 'Fecha'
    },
      {
        id: 'description',
        title: 'Descripción'
      },
      {
        id: 'metodo',
        title: 'Método de Pago'
      },
      {
        id: 'status',
        title: 'Estado'
      },
      {
        id: 'vencimiento',
        title: 'Vencimiento'
      },



    ];
    if (apiData2 && apiData2.payments && !user.coordinador) {
      table2 = <DataTable columns={headerst2} data={apiData2.payments} />;
    } else if (!user.coordinador) {
      table2 = <DataTable columns={headerst2} data={[]} />;
    }

    return <>
      <div className={'edit_user_text'}><Button onClick={() => {navigate('/user/edit');}} className={'button-edit-user'}>Editar perfil</Button></div>
      {table1}
      {table2}
      </>;
  }


  return (
    <>
      {user.name ? <TableMeets /> : ''}
    </>
  );
}
