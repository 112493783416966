import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {paypalClientId, processPayment, updatePaypalPayment} from "../../services";
import {useUser} from "../../hooks/use-user";
import {useNavigate} from "react-router-dom";


function Paypal(props) {
    const { accessToken, setAccessToken} = useUser();
    const navigate = useNavigate()

    return <PayPalScriptProvider
                    options={{ "client-id": paypalClientId }}
                >
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return processPayment(
                                accessToken,
                                props.id,
                                null,
                                (error) => setAccessToken(null)
                            );
                        }}
                        onApprove={async (data, actions) => {
                            return updatePaypalPayment(
                                accessToken,
                                data.orderID,
                                () => navigate('/home'),
                                (error) => setAccessToken(null)
                            )
                        }}
                    />
                </PayPalScriptProvider>;

}

export default Paypal;

