import React from 'react';
import ChargeAccount from '../../components/charge-account';
import { useUser } from '../../hooks/use-user';
import { Navigate } from "react-router-dom";

function ChargeAccountPage() {
    const { user } = useUser();
    return (
        <>
            {user && user.name ? <ChargeAccount /> : <Navigate to="/login" />}
        </>
    );
}

export default ChargeAccountPage;