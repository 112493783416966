import React, {useEffect} from 'react';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Button} from '@mui/material';
import {reportPayment} from "../../services";

function ProcessedPayment() {
    const { status } = useParams();

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }


    let query = useQuery();
    const external_reference = query.get("external_reference");

    const navigate = useNavigate();


    useEffect(() => {
        //console.log(external_reference)
        reportPayment(external_reference);
    }, []);

    return (
        <>
            <div className={'parent_div_loading'}>
                <h3 className={'child_h3_loading'}>
                    {(status === 'approved') ? 'Gracias por adquirir el pack de acceso a las reuniones de FUNDAPAP.' : ''}
                    {(status === 'failure') ? 'Su pago ha sido rechazado, si tiene dudas por favor comuníquese con el banco emisor de la tarjeta' : ''}
                    {(status === 'pending') ? 'Por favor realice las acciones indicadas por Mercado Pago y al finalizar encontrara el pack en su cuenta' : ''}
                </h3>
            </div>
            <Button className={'processed-payment_gotohome-button'} onClick={() => navigate('/home')} autoFocus>
                Volver a Inicio
            </Button>
        </>
    );
}

export default ProcessedPayment;
