import React from 'react';
import { Link } from "react-router-dom";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';


function Highlight(props) {
  let button;
  if (props.button) {
      if (props.saldo === -2) {
          button = <Link className="button_redirect" to={'/chargeaccount'} >Adquirir pack de reuniones</Link>
      } else {
          button = <Link className="button_redirect" to={props.button.href} >{props.button.text}</Link>
      }
  }
  let text = (props.saldo === -2) ? 'Por favor realice una contribución para ingresar a la reunión' : props.text;

  return (
      <div className={'parent_div_highlight'}>
          <div className={'parent_h_highlight'}>
              <h3 className={'child_h4_highlight_saldo'}>Saldo: {((props.saldo !== '*') ? props.saldo : <AllInclusiveIcon sx={{margin: '-2px', fontSize: '1.3rem'}}/> )} reuniones</h3>
              <h2 className={'child_h3_highlight'}>{props.title}</h2>
              <h3 className={'child_h4_highlight'}>{props.subtitle}</h3>
              <span className={'child_h4_highlight'}>{text}</span>
              {(button) ? button : ''}
          </div>
      </div>
  );
}

export default Highlight;
