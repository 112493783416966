import React, {useEffect, useState} from 'react';
import { useUser } from '../../hooks/use-user';
import {getProducts, processPayment} from "../../services";
import Loading from "../../components/loading";
import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Radio,
    RadioGroup
} from "@mui/material";
import {Avatar, Typography} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import Paypal from "../../components/paypal";


function ChargeAccount() {
    const {user, accessToken, setAccessToken} = useUser();
    const [products, setProducts] = useState();
    const [selected, setSelected] = useState(1);
    const [text, setText] = useState(<span>Proceder al pago</span>);
    const [processing, setProcessing] = useState(false);
    const [button, setButton] = useState((user.country === 'AR') ? 'mpButton' : 'paypalButton');


    const paymentProcess = () => {
        setProcessing(true);
        setText(<span><CircularProgress size={'1rem'} className={'payment-loading'}/> Aguarde por favor...</span>);

        processPayment(
            accessToken,
            selected,
            (data) => window.location.href = data.url,
            (error) => setAccessToken(null)
        );
    }

    useEffect(() => {
        getProducts(accessToken,
            (data) => setProducts(data),
            (error) => setAccessToken(null)
        );
    }, []);

    const handleChange = (e) => setSelected(e.target.value)



    if (!user || !user.name) {
        return ''
    } else if (products === undefined) { // Aun no cargo la api y hay que esperar
        return <Loading/>
    } else { //Ya esta la data de la api
        let mpButton = <Button
            fullWidth
            variant="contained"
            disabled={processing}
            onClick={() => paymentProcess()}
            className={'chargeaccount-button'}
        >
            {text}
        </Button>

        let paypalButton = <Button
            fullWidth
            variant="contained"
            disabled={processing}
            onClick={() => {
                setProcessing(true);
                setButton('PP');
            }}
            className={'chargeaccount-button'}
        >
            {text}
        </Button>

        let bt = ((button === 'mpButton') ?  mpButton : ((button === 'paypalButton') ? paypalButton : <Paypal id={selected} />)) ;


        let form =
        <main className={'chargeaccount-main'}>
            <Paper className={'chargeaccount-paper'}>
                <Avatar className={'chargeaccount-avatar'}>
                    <PaymentIcon fontSize={"large"} />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Adquiera un pack de reuniones
                </Typography>
                <form className={'chargeaccount-form'} onSubmit={(e) => e.preventDefault()}>
                    <FormControl margin="normal" required fullWidth>
                    <FormLabel className={'chargeaccount-formlabel'}>Seleccione una de las siguientes opciones</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-pack-option"
                        onChange={handleChange}
                        disabled={processing}
                    >
                        {products.map((elem) => {
                            let label = elem.description;
                            let cost = (user.country === 'AR') ? `$${elem.ar_value}` : `u$s${elem.other_value}`;
                            return <React.Fragment  key={'prod' + elem.id}>
                                <FormControlLabel className={'chargeaccount-formcontrollabel'}
                                                  value={elem.id} control={<Radio
                                                    className={'chargeaccount-radio'}/>}
                                                    label={label}
                                                  disabled={processing}/>
                                <span className={'value'}>Contribución: <b>{cost}</b></span>
                                <span className={'dueDate'}>Vencimiento de {elem.days} días (Estimado: {elem.date})</span>
                            </React.Fragment>
                        })}
                        </RadioGroup>
                        {bt}
                    </FormControl>
                </form>
            </Paper>
        </main>

        return form;

    }
}

export default ChargeAccount;