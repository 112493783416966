export const serverUrl = process.env.REACT_APP_BACKEND_URL;
export const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const login = async (email, password, callback) => {
    let url = `${serverUrl}user/login`;

    const data = {
        email,
        password
    }

    const fetchData = {
        method: "POST",
        body: getFormData(data)
    }

    return await fetch( url, fetchData
        ).then(response => {
            return response.json()
        }).then((data) => {
            callback(data);
        }).catch(error => console.log(error));
}

export const reportPayment = async (reference) => {

    let url = `${serverUrl}payment/${reference}` ;

    const fetchData = {
        method: "GET",
    }

    return await fetch( url, fetchData
    ).then(response => {
        return response.json()
    }).then((data) => {

    }).catch(error => console.log(error));

}

export const getBasicData = async (api_token, callback) => {
    let url = `${serverUrl}user/basicdata`;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        return response.json()
    }).then((data) => {
        callback(data);
    }).catch(error => console.log(error));
}

export const signin = async (firstname, lastname, email, password, phone_number, country, callback) => {
    let url = serverUrl + "user/signin";

    const data = {
        firstname,
        lastname,
        email,
        password,
        phone_number,
        country
    }

    const fetchData = {
        method: "POST",
        body: getFormData(data)
    }

    return await fetch( url, fetchData
    ).then(response => {
        return response.json()
    }).then((data) => {
        callback(data);
    }).catch(error => console.log(error));
}

export const edituser = async (api_token, firstname, lastname, email, password, phone_number, country, callback) => {
    let url = serverUrl + "user";

    const data = {
        firstname,
        lastname,
        email,
        password,
        phone_number,
        country
    }

    const fetchData = {
        method: "POST",
        body: getFormData(data),
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        return response.json()
    }).then((data) => {
        callback(data);
    }).catch(error => console.log(error));
}

export const logout = async (api_token, callback) => {
    let url = serverUrl + "user/logout";

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        return response.json()
    }).then((data) => {
        callback(data);
    }).catch(error => console.log(error));
}

export const meetingHistory = async (api_token, callback, errorCallback) => {
    let url = serverUrl + "user/meetingshistory";
    var status;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        } else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

export const getUserData = async (api_token, callback, errorCallback) => {
    let url = serverUrl + "user";
    var status;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        } else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

export const nextMeetings = async (api_token, callback, errorCallback) => {
    let url = serverUrl + "meeting/nextmeetings";
    var status;
    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        } else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

export const getMeeting = async (api_token, id, callback, errorCallback) => {
    let url = serverUrl + "meeting/" + id;
    var status;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        }else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

export const getProducts = async (api_token, callback, errorCallback) => {
    let url = serverUrl + "product/list";
    var status;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        }else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

export const processPayment = async (api_token, product_id, callback, errorCallback) => {
    let url = serverUrl + "payment";
    var status;

    const fetchData = {
        method: "POST",
        body: getFormData({product_id}),
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        }else {
            if (callback !== null) {
                callback(data);
            } else {
                return data.id;
            }
        }
    }).catch(error => errorCallback(error));
}

export const updatePaypalPayment = async (api_token, id, callback, errorCallback) => {
    let url = serverUrl + "payment/paypal/" + id;
    var status;

    const fetchData = {
        method: "POST",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        }else {
            if (callback !== null) {
                callback(data);
            }
        }
    }).catch(error => errorCallback(error));
}






export const listPayments = async (api_token, callback, errorCallback) => {
    let url = serverUrl + "payment/list";
    var status;

    const fetchData = {
        method: "GET",
        headers: {
            'Authorization': "Bearer " + api_token,
        },
    }

    return await fetch( url, fetchData
    ).then(response => {
        status = response.status;
        return response.json()
    }).then((data) => {
        if (status === 401) {
            errorCallback(null)
        }else {
            callback(data);
        }
    }).catch(error => errorCallback(error));
}

const getFormData = (object) => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
}, new FormData());