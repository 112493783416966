import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/home';
import LogIn from './pages/login';
import SignIn from './pages/signin';
import User from './pages/user';
import Meet from './pages/meet';
import {useUser} from './hooks/use-user';
import AppBarNew from './components/app-bar';
import ProcessedPayment from './components/processed-payment';
import ChargeAccountPage from './pages/charge-account';
import Edituser from "./components/edituser";
import Loading from "./components/loading";
import {getBasicData} from "./services";


function App() {
  const { user, accessToken, setUser } = useUser();

  useEffect(() => {
      if (accessToken && user && user.name && user.country === undefined) {
        getBasicData(accessToken, (data) => {
              let userLog = {
                name: `${data.firstname} ${data.lastname}`,
                coordinador: data.coordinador,
                country: data.country,
              };
              setUser(userLog)
            }
        );
      }
    },[]);

    if (accessToken && user && user.name && user.country === undefined) {
      return <Loading />
    } else {

      return (
          <BrowserRouter>
            <div className="App">
              <AppBarNew/>
              <Routes>
                <Route exact path='/' element={<LogIn/>}/>
                <Route path='/home' element={<Home/>}/>
                <Route path='/login' element={<LogIn/>}/>
                <Route path='/signin' element={<SignIn/>}/>
                <Route path='/user/edit' element={<Edituser/>}/>
                <Route path='/user' element={<User/>}/>
                <Route path='/chargeaccount' element={<ChargeAccountPage/>}/>
                <Route path='/meet/:uuid' element={<Meet/>}/>
                <Route path='/processed-payment/:status' element={<ProcessedPayment/>}/>
              </Routes>
            </div>
          </BrowserRouter>
      );
    }
  }


export default App;
