import React, {useEffect, useState} from 'react';
import { useUser } from '../../hooks/use-user';
import {Navigate, useNavigate} from "react-router-dom";
import { nextMeetings } from "../../services";
import Loading from "../../components/loading";
import Highlight from "./highlight";
import Calendar from "./calendar";
import {Alert, Button, Snackbar} from "@mui/material";


export default function Home() {
  const { user, accessToken, setAccessToken } = useUser();
  const [apiData, setApiData] = useState();
  const [open, setOpen] = useState( true);
  const navigate = useNavigate();

  useEffect(() => {
      nextMeetings(accessToken,
          (data) => setApiData(data),
          (error) => {setAccessToken(null)}
          //() => true // Comentar arriba y descomentar aca para ver loading
      );
  }, []);

  const render = () => {
    if (!user || !user.name) { //No esta logueado
      return <Navigate to="/login"/>
    } else if (apiData === undefined) { // Aun no cargo la api y hay que esperar
      return <Loading />
    } else { 
    // Actual meeting
      let meetingMessage;
      let gotoBuy
      let saldo;
      if (apiData && user && apiData.saldo && apiData.saldo === "0" && user.coordinador === false && user.country === "AR") {
        gotoBuy =
            <Snackbar
                open={open}
                autoHideDuration={60000}
                onClose={() => setOpen(false)}
                message={'podrá acceder a las reuniones, adquiera un pack para acceder a las mismas haciendo click aquí'}
                action={() => navigate('/chargeaccount')}
            >
              <Alert
                  className={'home-alert'}
                  elevation={12}
                  title={'Atención'}
                  action={
                  <Button color="inherit" size="small" onClick={() => navigate('/chargeaccount')}>
                    Adquirir
                  </Button>
                  }
                  variant="filled"
                  severity="warning">
                No podrá acceder a las reuniones, adquiera un pack para acceder a las mismas haciendo click en el siguiente boton
              </Alert>
            </Snackbar>
        // <Remarked text={'No podrá acceder a las reuniones, adquiera un pack para acceder a las mismas haciendo click aquí'} button={{to: '/chargeaccount'}}/>;
      }
/*
      if (apiData && apiData.saldo ) {
        saldo =
            <Avatar
                sx={{width: 50, height: 50}}
                className={'home-avatar-saldo'}
                variant="rounded"
            >
              <Badge badgeContent={(apiData.saldo === '*') ? <AllInclusiveIcon sx={{fontSize: 12}}/> : apiData.saldo}
                     color="secondary">
                <GroupsIcon className="action"/>
              </Badge>
            </Avatar>;
      }*/

      if (apiData && apiData.current && apiData.current.length === 0) {
        let title = `Actualmente no hay ninguna reunión en curso`;
        let subtitle = `Encontrarás el link para ingresar aqui, a partir de ${apiData.time_before} minutos antes del inicio.`;
        meetingMessage = <Highlight title={title} saldo={apiData.saldo} subtitle={subtitle} />;
      } else {
        // Textos de current meet
        let current = apiData.current[0];
        meetingMessage = <Highlight 
          title={'Próxima reunión'}
          saldo={apiData.saldo}
          subtitle={current.title}
          text={'Puedes unirte a la reunión presionando en el siguiente boton'}
          button={{
            text: "Ir a la reunión",
            href: `/meet/${current.code}`,
          }}
        />
      }

      //nextMeetings
      let nextMeetings;
      if (apiData.next.length > 0) {
        nextMeetings = <Calendar nexts={apiData.next}/>
      }

      // Maqueta del contenido
      return <>
        {saldo}
        {meetingMessage}
        {nextMeetings}
        {gotoBuy}
      </>
    }
  }

  return render();

}
